import { router } from '@inertiajs/react';
import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface AuthState {
  user?: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    profile_image: string;
    roles: string;
    email: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
  };
}

export interface ISharedData {
  auth: AuthState | any;
  companySlug: string | unknown;
  currentBusiness: any;
  businessProfiles: any;
  userAccess: any;
}

interface ChatBubbleProps {
  className?: string;
  title?: ReactNode;
  message?: ReactNode;
  children?: ReactNode;
}

interface InertiaContextType {
  auth: AuthState | any;
  userAccess: any;
  refreshSharedData: (params?: any) => Promise<void>;
  companySlug: string | unknown;
  currentBusiness: any;
  businessProfiles: [];
  bubble: ChatBubbleProps;
  setBubble: React.Dispatch<React.SetStateAction<ChatBubbleProps>>;
  showingNavigationDropdown: boolean;
  setShowingNavigationDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSharedData: React.Dispatch<React.SetStateAction<ISharedData>>;
}

const InertiaContext = createContext<InertiaContextType | null>(null);

export const InertiaProvider: React.FC<{
  children: ReactNode;
  pageProps: any;
}> = ({ children, pageProps }) => {
  const [bubble, setBubble] = useState<ChatBubbleProps>({
    title: '',
    message: '',
    className: '',
  });
  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sharedDataProps: ISharedData = {
    auth: pageProps.auth,
    companySlug: pageProps.companySlug,
    currentBusiness: pageProps.currentBusiness,
    businessProfiles: pageProps.businessProfiles,
    userAccess: pageProps.userAccess,
  };

  const [sharedData, setSharedData] = useState(sharedDataProps);

  const refreshSharedData = async (options: any = {}) => {
    const mergedOptions = {
      only: [
        'auth',
        'companySlug',
        'currentBusiness',
        'businessProfiles',
        'userAccess',
      ],
      preserveScroll: true,
      ...options,
    };

    try {
      router.visit(route(route().current(), route().params), mergedOptions);
    } catch (error) {
      console.error('Failed to refresh shared data:', error);
      throw error;
    }
  };

  const value = {
    refreshSharedData,
    bubble,
    setBubble,
    showingNavigationDropdown,
    setShowingNavigationDropdown,
    isLoading,
    setIsLoading,
    ...sharedData,
    setSharedData,
  };

  return (
    <InertiaContext.Provider value={value}>{children}</InertiaContext.Provider>
  );
};

export const useInertiaContext = () => {
  const context = useContext(InertiaContext);
  if (context === null) {
    throw new Error('useInertiaContext must be used within an InertiaProvider');
  }
  return context;
};
