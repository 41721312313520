import { useInertiaContext } from '@/Contexts/InertiaContext';
import { router } from '@inertiajs/react';
import { ExpandedState } from '@tanstack/react-table';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Filters = Record<string, any>;

interface TanStackTableContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  refreshPagination: (updatedFilters?: Filters) => void;
  updateFilters: (updates: Partial<Filters>) => void;
  resetFilters: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchChange: (value: string) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  refreshKey: string;
  setRefreshKey: React.Dispatch<React.SetStateAction<string>>;
  expanded: ExpandedState;
  setExpanded: React.Dispatch<React.SetStateAction<ExpandedState>>;
}

interface TanStackTableProviderProps {
  children: React.ReactNode;
}

const TanStackTableContext = createContext<
  TanStackTableContextType | undefined
>(undefined);

export const TanStackTableProvider: React.FC<TanStackTableProviderProps> = ({
  children,
}) => {
  const initialFilters = useMemo(
    () =>
      ({
        search_term: '',
      } as any),
    []
  );

  const [refreshKey, setRefreshKey] = useState<string>('InitialKey');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { companySlug } = useInertiaContext();

  const refreshPagination = useCallback(
    (updatedFilters: Filters = filters) => {
      const newFilters: any = {
        ...filters,
        ...updatedFilters,
        page: updatedFilters.page || currentPage,
      };

      if (companySlug) {
        newFilters.companySlug = companySlug;
      }

      router.visit(route(route().current(), newFilters), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          setRefreshKey(Math.random().toString());
        },
      });
    },
    [filters, currentPage]
  );

  const updateFilters = useCallback((updates: Partial<Filters>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...updates,
    }));
  }, []);

  useEffect(() => {
    refreshPagination(filters);
  }, [filters, refreshPagination]);

  const resetFilters = useCallback(() => {
    setFilters(initialFilters);
    refreshPagination(initialFilters);
  }, [initialFilters, refreshPagination]);

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      updateFilters({ search_term: value });
    },
    [updateFilters]
  );

  return (
    <TanStackTableContext.Provider
      value={{
        filters,
        setFilters,
        refreshPagination,
        updateFilters,
        resetFilters,
        searchTerm,
        setSearchTerm,
        handleSearchChange,
        currentPage,
        setCurrentPage,
        refreshKey,
        setRefreshKey,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </TanStackTableContext.Provider>
  );
};

export const useTanStackTable = () => {
  const context = useContext(TanStackTableContext);
  if (context === undefined) {
    throw new Error(
      'useTanStackTable must be used within a TanStackTableProvider'
    );
  }
  return context;
};
