import { useInertiaContext } from '@/Contexts/InertiaContext';
import FullScreenLoader from '@/Layouts/FullScreenLoader';
import { Inertia } from '@inertiajs/inertia';
import React, { useEffect, useRef } from 'react';

const LoaderLayout = ({ children }) => {
  const { isLoading, setIsLoading } = useInertiaContext();
  const lastLoadingTime = useRef(0);

  useEffect(() => {
    const handleStart = () => {
      const now = Date.now();
      if (now - lastLoadingTime.current > 2000) {
        //setIsLoading(true);
        lastLoadingTime.current = now;
      }
    };

    const handleFinish = () => {
      //setIsLoading(false);
    };

    const startHandler = Inertia.on('start', handleStart);
    const finishHandler = Inertia.on('finish', handleFinish);

    return () => {
      startHandler();
      finishHandler();
    };
  }, [setIsLoading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {children}
    </>
  );
};

export default LoaderLayout;
